import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

import "./style.css"

// import swagger.yml
import swagger from "./swagger.yml"

function ApiDoc() { 

  // add background: black; to the body tag to enable dark mode
  document.body.style.backgroundColor = "black";
  return (
    <SwaggerUI url={swagger} />
  );
}

export default ApiDoc;