
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';

import './style.css';

function Header() {
    const [finishedLoading, setFinishedLoading] = useState(false);
    let menuSelect = window.location.pathname;

    let defaultMenus = [
        { url: '/', name: 'Home', icon: 'fa-solid fa-house' },
        { url: '/best_routes', name: 'Top 5', icon: 'fa-solid fa-compass' },
        { url: '/tradepack_calculator', name: 'Tradepack Calculator', icon: 'fa-solid fa-calculator' },
        { url: '/infusion_calculator', name: 'Infusion Calculator', icon: 'fa-solid fa-bolt' },
        { url: '/crafting_calculator', name: 'Crafing Calculator', icon: 'fa-solid fa-hammer' },
        { url: 'https://discord.com/oauth2/authorize?client_id=1256415991458431037', name: '', icon: 'fa-brands fa-discord', blank: true },

    ];

    for (let i = 0; i < defaultMenus.length; i++) {
        let menu = defaultMenus[i];
        menu.active = false;
        if (menu.url === menuSelect) {
            menu.active = true;
        }
    }

    const [menus, setMenus] = useState(defaultMenus);

    function onChangeMenu(event) {
        let menuSelect = event.target.getAttribute('href');

        for (let i = 0; i < menus.length; i++) {
            let menu = menus[i];
            menu.active = false;
            if (menu.url === menuSelect) {
                menu.active = true;
            }
        }
        setMenus([...menus]);
    }

    useEffect(() => {
        // set in local storage
        // let worldStorage = localStorage.getItem('world');
        localStorage.setItem('world', 'angerhorn');
        setFinishedLoading(true);
    }, [menus]);


    if (finishedLoading) {
        return (
            <header>
                <div className="px-3 py-2 bg-dark text-white">
                    <div className="container">
                        <div className="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-start">
                            <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">

                                {menus.map((menu) => (  
                                    <li key={menu.name}>
                                        <Link to={menu.url} className={`nav-link text-secondary ${menu.active ? 'text-white' : ''}`} onClick={onChangeMenu} target={`${menu.blank === true ? '_blank': ''}`}>
                                            <i className={`${menu.icon} mb-1 mx-auto`}></i>
                                            {menu.name}
                                        </Link>
                                    </li>
                                ))}

                            </ul>
                            <div className="text-white ms-lg-auto" style={{ fontSize: "small" }}>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;