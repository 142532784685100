import React from 'react';
import { Line } from 'react-chartjs-2';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { fetchItemMetric } from '../../api/metrics';

async function loadItemMetric(id) {
    let world = localStorage.getItem('world');
    let data = await fetchItemMetric(id, world);
    if (data.pricing) {
        data.pricing.pop();
    }
    return data;
}


const MetricPopover = ({ clientid, name, image, medianPrice, smallestPrice, highestPrice, metricData, setMetricData, children }) => {
    let item = {
        clientId: clientid,
        name: name,
        image: image,
        marketPrices: {
            smallestPrice: smallestPrice,
            medianPrice: medianPrice,
            highestPrice: highestPrice
        },
        metrics: {
            pricing: []
        }
    };
    const handleMouseEnter = async () => {
        if (metricData[clientid]) {
            return;
        }

        let data = await loadItemMetric(clientid);
        item.metrics = data;

        if (!data.pricing) {
            return;
        }

        let prices = [];
        let labels = [];
        let median = 0;
        let cont = 1;

        for (const metric of item.metrics.pricing) {
            prices.push(metric.price);
            median += metric.price;
            labels.push(cont);
            cont++;
        }
        debugger
        median = median / item.metrics.pricing.length;

        let lastPrice = item.marketPrices.medianPrice;
        let diff = lastPrice - median;

        prices.push(lastPrice);
        labels.push(cont);

        const lineData = {
            labels,
            datasets: [
                {
                    drawActiveElementsOnTop: false,
                    fill: true,
                    pointStyle: false,
                    tension: 0.4,
                    label: 'Median Price',
                    data: prices,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                }
            ],
        };

        let metrics = {
            lineData: lineData,
            diff: diff,
            median: median,
            countData: item.metrics.pricing.length
        };

        setMetricData(prevData => ({
            ...prevData,
            [clientid]: metrics
        }));
    };

    const options = {
        responsive: true,
        animation: false,
        layout: {
            padding: 0
        },
        datasets: {
            line: {
                pointRadius: 0
            }
        },
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            title: {
                display: false,
                text: 'Median Price',
            },
            tooltip: {
                enabled: false
            }
        },
        scales: {
            y: {
                suggestedMin: 0,
                display: true
            },
            x: {
                display: false
            }
        }
    };

    const popover = (
        <Popover id="popover-basic" style={{ maxWidth: '550px' }}>
            <Popover.Header as="h3">
                <span className="me-2">Market Price:</span>
                <img src={item.image} alt={item.name} title={item.name} width={25} height={25} />
                {item.name}
            </Popover.Header>
            <Popover.Body>
                {metricData[clientid] ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center">
                            {(item.marketPrices.medianPrice) && (
                                <div className="text-end">
                                    <h6>Median: <small>{item.marketPrices.medianPrice}</small></h6>
                                    <h6>Smallest: <small>{item.marketPrices.smallestPrice}</small></h6>
                                    <h6>Highest: <small>{item.marketPrices.highestPrice}</small></h6>
                                </div>
                            )}
                            <div className="m-1">
                                <Line options={options} data={metricData[clientid].lineData} />
                            </div>
                            {(item.marketPrices.medianPrice) && (
                                <div className="flex-grow-1 d-flex flex-column align-items-center">
                                    <small>{metricData[clientid].countData}d variation</small>
                                    <span className={(item.marketPrices.medianPrice - metricData[clientid].median) > 0 ? 'text-danger h4' : 'text-success h4'}>
                                        {(item.marketPrices.medianPrice - metricData[clientid].median) > 0 ? '+' : ''}
                                        {((item.marketPrices.medianPrice * 100) / metricData[clientid].median - 100).toFixed(2)}%
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="text-center">
                            <small>Last {metricData[clientid].countData} days median price: {metricData[clientid].median.toFixed(0)}.</small>
                        </div>
                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger={['hover', 'focus']} placement='bottom-end'  overlay={popover} onEnter={handleMouseEnter}>
            {children}
        </OverlayTrigger>
    );
};

export default MetricPopover;
