import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './index.css';
import App from './App';
import Home from './pages/home';
import BestRoutes from './pages/best_routes';
import TradePackCalculator from './pages/tradepack_calculator';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import InfusionCalculator from './pages/infusion_calculator';
import ApiDoc from './pages/apidoc';
import CraftingCalculator from './pages/crafting_calculator';
import CraftingMap from './pages/crafting_map';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/best_routes",
        element: <BestRoutes />,
      },
      {
        path: "/tradepack_calculator",
        element: <TradePackCalculator />,
      },
      {
        path: "/infusion_calculator",
        element: <InfusionCalculator />,
      },
      {
        path: "/crafting_calculator",
        element: <CraftingCalculator />,
      },
      {
        path: "/crafting_map",
        element: <CraftingMap />,
      }
    ],
  },
  {
    path: "/apidoc",
    element: <ApiDoc />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
