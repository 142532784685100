import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useState, useRef } from "react";
import { GraphCanvas, useSelection, darkTheme } from 'reagraph';

function CraftingMap() {
    document.title = 'Trade Track - Crafting Map';

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [collapsedNodes, setCollapsedNodes] = useState([]);
    const [profession, setProfession] = useState('Tradepacks');
    const [finishedLoading, setFinishedLoading] = useState(false);
    const professions = ["All", "Blacksmithing", "Carpentry", "Cooking", "Tradepacks", 'Alchemy']
    const graphRef = useRef(null);
    const {
        selections,
        actives,
        onNodeClick,
        onCanvasClick
    } = useSelection({
        ref: graphRef,
        nodes: nodes,
        edges: edges,
        pathSelectionType: 'all',
        type: 'multi',
    });

    function onChange(event) {
        setProfession(event.value);
    }

    useEffect(() => {
        async function fetchData() {
            try {
                setFinishedLoading(false);
                let selectedProfessions = [];
                if (profession === "All") {
                    selectedProfessions = ["Blacksmithing", "Carpentry", "Cooking", "Tradepacks", "Alchemy"]
                } else {
                    selectedProfessions = [profession]
                }

                let fetchedNodes = [];
                let fetchedEdges = [];
                let collapsedNodes = [];
                let categorys = [];
                for (let i = 0; i < selectedProfessions.length; i++) {
                    let profession = selectedProfessions[i];
                    let url = `https://api.tradetrack.online/v2/recipes/${profession}`;

                    if (profession === "Tradepacks") {
                        url = `https://api.tradetrack.online/v2/tradepacks`
                    }

                    const response = await fetch(url);
                    let data = await response.json();
                    if (data.constructor === Object) {
                        data = Object.values(data);
                    }

                    const professionNodeId = `n-${profession}`;
                    fetchedNodes.push({
                        id: professionNodeId,
                        label: profession,
                        data: {
                            category: profession
                        },
                    });

                    if (profession !== "Tradepacks") {
                        categorys = data.map(recipe => recipe.category);
                        categorys = [...new Set(categorys)];

                        for (let j = 0; j < categorys.length; j++) {
                            const categoryNodeId = `n-${categorys[j]}`;
                            fetchedNodes.push({
                                id: categoryNodeId,
                                label: categorys[j],
                                data: {
                                    category: profession
                                },
                                parents: [professionNodeId]
                            });

                            fetchedEdges.push({
                                id: `e-${profession}-${categorys[j]}`,
                                source: professionNodeId,
                                target: categoryNodeId
                            });
                        }
                    }

                    for (let j = 0; j < data.length; j++) {
                        const recipe = data[j];
                        const recipeNodeId = `n-${recipe.itemid}`;
                        let amount = recipe.amount | 1
                        let category = recipe.category || profession;

                        fetchedEdges.push({
                            id: `e-${recipe.itemid}`,
                            source: `n-${category}`,
                            target: recipeNodeId,
                            parent: `n-${category}`,
                        });

                        // Create nodes for materials
                        if (recipe.materials) {
                            for (const material of recipe.materials) {
                                amount += material.amount | 1
                                const nodeId = `n-${material.itemid}`;
                                let node = fetchedNodes.find(n => n.id === nodeId);

                                if (!node) {
                                    node = {
                                        id: nodeId,
                                        label: material.name,
                                        data: {
                                            category: category,
                                            amount: material.amount | 0
                                        }
                                    };
                                    fetchedNodes.push(node);
                                } else {
                                    node.data.amount += material.amount | 0;
                                    node.data.category = 'material'
                                }

                                // Create edge between material and recipe
                                const edgeId = `e-${material.itemid}-${recipe.itemid}`;
                                let edge = fetchedEdges.find(e => e.id === edgeId);

                                if (!edge) {
                                    edge = {
                                        id: edgeId,
                                        source: nodeId,
                                        target: `n-${recipe.itemid}`,
                                        label: material.amount.toString()
                                    };
                                    fetchedEdges.push(edge);
                                }
                            }
                        }

                        // Create node for recipe
                        let recipeNode = fetchedNodes.find(n => n.id === recipeNodeId);

                        if (!recipeNode) {
                            console.log(recipe.name, amount)
                            recipeNode = {
                                id: recipeNodeId,
                                label: recipe.name,
                                data: {
                                    category: category,
                                    amount: amount
                                },
                                fill: "blue"
                            };
                            fetchedNodes.push(recipeNode);
                        } else {
                            recipeNode.data.amount += recipe.amount | 0;
                        }
                    }
                }

                setNodes(fetchedNodes);
                setEdges(fetchedEdges);
                setCollapsedNodes(collapsedNodes);
                setFinishedLoading(true);
                console.log('Data fetched:', fetchedNodes, fetchedEdges);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [profession]);

    return (
        <div className="col-12">
            <h1>Crafting Map</h1>
            <div>
                <SelectButton options={professions} value={profession} onChange={onChange} />
            </div>
            {finishedLoading &&
                <div style={{ position: "relative", width: '100%', height: '800px' }}>
                    <GraphCanvas
                        // layoutType="forceatlas2"
                        animated={false}
                        theme={darkTheme}
                        nodes={nodes}
                        edges={edges}
                        collapsedNodeIds={collapsedNodes}
                        // clusterAttribute="category"
                        sizingAttribute="amount"
                        sizingType="attribute"
                        labelType="all"
                        pathSelectionType="in"
                        ref={graphRef}
                        selections={selections}
                        actives={actives}
                        onCanvasClick={onCanvasClick}
                        onNodeClick={(node, event) => {
                            onNodeClick(node, event);
                            console.log('Node clicked:', node);
                        }}
                        draggable
                    />
                </div>
            }
        </div>
    );
}

export default CraftingMap;
