import protobufjs from 'protobufjs';
import itemMetricJson from './proto/item_metric.json';

const root = protobufjs.Root.fromJSON(itemMetricJson);
const ItemMetric = root.lookupType('ravendawn.ItemMetric');

const fetchItemMetric = async (id, world) => {
    try {
        console.log(`Fetching item metric for ${id}`)
        const response = await fetch(`${process.env.REACT_APP_TRADEPACK_API}/v2/item_metric?world=${world}&clientId=${id}`);
        if (response.status !== 200) {
            return {
                found: false,
            }
        }
        const buffer = await response.arrayBuffer();
        console.log(`Received item metric for ${id} with ${buffer.byteLength} bytes`)
        const itemMetric = ItemMetric.decode(new Uint8Array(buffer));
        const obj = ItemMetric.toObject(itemMetric);
        obj.found = true;
        return obj;
    } catch (e) {
        console.error(e);
        return {
            found: false,
        }
    }
}

export { fetchItemMetric };
