import banner_1 from '../../assets/images/banner_1.webp';
import banner_2 from '../../assets/images/banner_2.webp';

function Home() {
    document.title = 'Trade Track';
    return (
        <div className="card" style={{border: 0}}>
            <div className="card-body" style={{border: 0}}>
                <div className="row">
                    <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0"
                                className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                                aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="5000">
                                <a href="/best_routes">

                                    <img src={banner_1} className="d-block w-100" alt=""/>
                                </a>
                            </div>
                            <div className="carousel-item" data-bs-interval="5000">
                                <a href="/tradepack_calculator">
                                    <img src={banner_2} className="d-block w-100" alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;