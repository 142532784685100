import { useEffect, useState } from "react";


function TradePackCalculator() {
    document.title = 'Trade Track - Trade Pack Calculator';

    const [calculateLoading, setCalculateLoading] = useState(false);
    const [tradepacks, setTradepacks] = useState([]);
    const [tradeposts, setTradeposts] = useState([]);
    const [tradepack, setTradepack] = useState({});
    const [tradepostOrigin, setTradePostOrigin] = useState({});
    const [tradepostDestination, setTradePostDestination] = useState({});
    const [results, setResults] = useState([]);
    const [calculateMode, setCalculateMode] = useState(0);

    // 5% bonus
    const [bonus5, setBonus5] = useState(false);
    // 10% bonus
    const [bonus10, setBonus10] = useState(false);

    const [typeChannel, setTypeChannel] = useState('normal');

    function onTypeChannelChange(event) {
        let value = event.target.value;
        setTypeChannel(value);
    }

    function onCheckboxChange(event) {
        let id = event.target.id;
        let checked = event.target.checked;

        if (id === '5_bonus') {
            setBonus5(checked);
        } else if (id === '10_bonus') {
            setBonus10(checked);
        }
    }

    async function fetchData(world) {
        const response = await fetch(`${process.env.REACT_APP_TRADEPACK_API}/tradepacks?world=${world}`);
        const data = await response.json();

        let tradepacks = data;
        let tradeposts = [];
        tradepacks.sort((a, b) => a.name.localeCompare(b.name));

        for (const tradepost of tradepacks[0].tradeposts) {
            tradeposts.push(tradepost);
        }

        tradeposts.sort((a, b) => a.name.localeCompare(b.name));

        setTradepacks(tradepacks);
        setTradeposts(tradeposts);
    }

    function onSelectedChange(event) {
        let id = event.target.id;
        let value = parseInt(event.target.value);

        if (id === 'tradepack') {
            let tradepack = tradepacks.find(tradepack => tradepack.id === value);
            setTradepack(tradepack || {});
        } else if (id === 'tradepostOrigin') {
            let tradepost = tradeposts.find(tradepost => tradepost.tradepostId === value);
            setTradePostOrigin(tradepost || {});
        } else if (id === 'tradepostDestination') {
            let tradepost = tradeposts.find(tradepost => tradepost.tradepostId === value);
            setTradePostDestination(tradepost || {});
        }
    }

    async function calculateTradePack() {
        let bonus = 0;

        if (!tradepack.id && !tradepostOrigin.tradepostId && !tradepostDestination.tradepostId) {
            alert('[1] Select a tradepack and a tradepost origin or destination, or both origin and destination.');
            return;
        }

        // if tradePackId, tradepostOriginId, tradepostDestinationId  is not empty
        if (tradepack.id && tradepostOrigin.tradepostId && tradepostDestination.tradepostId) {
            alert('[2] Select a tradepack and a tradepost origin or destination, or both origin and destination.');
            return;
        }

        setCalculateLoading(true);
        // set button calculate to disabled
        document.getElementById('calculate').disabled = true;

        let world = localStorage.getItem('world');
        try {
            await fetchData(world);

            await new Promise(r => setTimeout(r, 250));

            if (bonus5) {
                bonus += 0.05;
            }

            if (bonus10) {
                bonus += 0.10;
            }

            if (typeChannel === 'warmode') {
                bonus += 0.15;
            } else if (typeChannel === 'plunder') {
                bonus += 0.25;
            }

            let results = [];
            if (tradepack.id) {
                let tradepackPrice = 0;
                for (const material of tradepack.materials) {
                    let amount = material.amount;
                    tradepackPrice += material.pricing.medianPrice * amount;
                }
                tradepack.price = tradepackPrice;

                if (tradepostOrigin.tradepostId) {
                    let tradepost = tradepack.tradeposts.find(tradepost => tradepost.tradepostId === tradepostOrigin.tradepostId);
                    for (const route of tradepost.routes) {
                        let distance = route.distance;
                        let demand = route.demand;
                        let price = route.price * (1 + bonus);
                        let profit = price - tradepack.price;

                        price = price.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        profit = profit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        demand = demand.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                        let result = {
                            tradepostDestination: route.tradepostName,
                            demand: demand,
                            distance: distance,
                            price: price,
                            profit: profit
                        }

                        results.push(result);
                    }
                    setResults(results);
                    setCalculateMode(1);

                } else if (tradepostDestination.tradepostId) {
                    for (const tradepost of tradepack.tradeposts) {
                        for (const route of tradepost.routes) {
                            if (route.tradepostId === tradepostDestination.tradepostId) {
                                let distance = route.distance;
                                let demand = route.demand;
                                let price = route.price * (1 + bonus);
                                let profit = price - tradepack.price;

                                results.push({
                                    tradepost: tradepost,
                                    demand: demand,
                                    distance: distance,
                                    price: price,
                                    profit: profit
                                });
                                break;
                            }
                        }
                    }
                    // sort by profit
                    results.sort((a, b) => b.profit - a.profit);
                    setResults(results);
                    setCalculateMode(2);
                }
            } else if (tradepostOrigin.tradepostId && tradepostDestination.tradepostId) {
                for (const tradepack of tradepacks) {
                    tradepack.price = 0;
                    for (const material of tradepack.materials) {
                        let amount = material.amount;
                        tradepack.price += material.pricing.medianPrice * amount;
                    }

                    for (const tradepost of tradepack.tradeposts) {
                        if (tradepost.tradepostId === tradepostOrigin.tradepostId) {
                            for (const route of tradepost.routes) {
                                if (route.tradepostId === tradepostDestination.tradepostId) {
                                    let price = route.price * (1 + bonus);
                                    let profit = price - tradepack.price;

                                    results.push({
                                        tradepack: tradepack.name,
                                        demand: route.demand,
                                        craftPrice: tradepack.price,
                                        price: price,
                                        profit: profit
                                    });
                                }
                            }
                        }
                    }
                }

                // sort by profit
                results.sort((a, b) => b.profit - a.profit);

                setResults(results);
                setCalculateMode(3);
            } else {
                alert('[3] Select a tradepack and a tradepost origin or destination, or both origin and destination.');
            }
            setCalculateLoading(false);
            document.getElementById('calculate').disabled = false;
        } catch (error) {
            document.getElementById('calculate').disabled = false;
        }
    }

    useEffect(() => {
        let world = localStorage.getItem('world');

        fetchData(world);
    }, []);

    return (
        <div className="card">
            <div className="card-body">
                <div className="row" id="tradepacks">
                    <h5 className="card-title">Tradepack Calculator</h5>
                    {tradepacks.length === 0 && <div className="d-flex justify-content-center">
                        <div className="spinner-border m-5" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    }
                    {tradepacks.length > 0 && tradeposts.length > 0 &&
                        <form action="">
                            <div className="mb-3">
                                <label htmlFor="tradepack">Tradepack</label>
                                <select className="form-select" defaultValue={''} aria-label="Select the tradepack" id="tradepack" value={tradepack.id} onChange={onSelectedChange}>
                                    <option value="">Select the tradepack</option>
                                    {tradepacks.map((tradepack) => (
                                        <option value={tradepack.id}>{tradepack.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="tradepostOrigin">Tradepost Origin</label>
                                <select className="form-select" defaultValue={''} aria-label="Select the tradepost origin" id="tradepostOrigin" value={tradepostOrigin.tradepostId} onChange={onSelectedChange}>
                                    <option value="">Select the tradepost origin</option>
                                    {tradeposts.map((tradepost) => (
                                        <option value={tradepost.tradepostId}>{tradepost.name}</option>
                                    ))}

                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="tradepostDestination">Tradepost Destination</label>
                                <select className="form-select" aria-label="Select the tradepost destination"
                                    id="tradepostDestination" defaultValue={''} value={tradepostDestination.tradepostId} onChange={onSelectedChange}>
                                    <option value="">Select the tradepost destination</option>
                                    {tradeposts.map((tradepost) => (
                                        <option value={tradepost.tradepostId}>{tradepost.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="tradepostOrigin">Reputation bonuses: </label>

                                <div className="form-check form-check-inline" style={{ marginRight: "0px" }}>
                                    <input className="form-check-input" type="checkbox" value="" id="5_bonus" onChange={onCheckboxChange} />
                                    <label className="form-check-label" htmlFor="5_bonus">
                                        5%
                                    </label>
                                </div>
                                <div className="form-check form-check-inline" style={{ marginRight: "5px" }}>
                                    <input className="form-check-input" type="checkbox" value="" id="10_bonus" onChange={onCheckboxChange} />
                                    <label className="form-check-label" htmlFor="10_bonus">
                                        10% |
                                    </label>
                                </div>
                                <div className="form-check form-check-inline inline-checkbox">
                                    <input className="form-check-input" type="radio" name="typeChannel" value="normal" id="typeChannelNormal" checked={typeChannel === 'normal'} onChange={onTypeChannelChange} />
                                    <label className="form-check-label" htmlFor="typeChannelNormal">
                                        Normal
                                    </label>
                                </div>

                                <div className="form-check form-check-inline inline-checkbox">
                                    <input className="form-check-input" type="radio" name="typeChannel" value="warmode" id="typeChannelWarmode" checked={typeChannel === 'warmode'} onChange={onTypeChannelChange} />
                                    <label className="form-check-label" htmlFor="typeChannelWarmode">
                                        Warmode
                                    </label>
                                </div>

                                <div className="form-check form-check-inline inline-checkbox">
                                    <input className="form-check-input" type="radio" name="typeChannel" value="plunder" id="typeChannelPlunder" checked={typeChannel === 'plunder'} onChange={onTypeChannelChange} />
                                    <label className="form-check-label" htmlFor="typeChannelPlunder">
                                        Plunder
                                    </label>
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" id="calculate" onClick={calculateTradePack}>Calculate</button>
                        </form>
                    }
                    {calculateLoading &&
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border m-5" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    }
                    {!calculateLoading &&
                        <div className="row mb-3" id="results">
                            <table className="table  table-sm table-dark table-striped table-bordered m-3">

                                {calculateMode === 1 && results.length > 0 &&
                                    <>
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>Tradepost Destination</th>
                                                <th>Demand</th>
                                                <th>Distance</th>
                                                <th>Sell Price</th>
                                                <th>Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {results.map((result) => (
                                                <tr>
                                                    <td>{result.tradepostDestination}</td>
                                                    <td>{result.demand}%</td>
                                                    <td>{result.distance}</td>
                                                    <td>{result.price}</td>
                                                    <td>{result.profit}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </>
                                }
                                {calculateMode === 2 && results.length > 0 &&
                                    <>
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>Tradepost Origin</th>
                                                <th>Demand</th>
                                                <th>Distance</th>
                                                <th>Sell Price</th>
                                                <th>Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {results.map((result) => (
                                                <tr>
                                                    <td>{result.tradepost.name}</td>
                                                    <td>{result.demand.toFixed(0)}%</td>
                                                    <td>{result.distance}</td>
                                                    <td>{result.price.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                    <td>{result.profit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </>
                                }
                                {calculateMode === 3 && results.length > 0 &&
                                    <>
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>Tradepack</th>
                                                <th>Demand</th>
                                                <th>Crafting Price</th>
                                                <th>Sell Price</th>
                                                <th>Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {results.map((result) => (
                                                <tr>
                                                    <td>{result.tradepack}</td>
                                                    <td>{result.demand.toFixed(0)}%</td>
                                                    <td>{result.craftPrice.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                    <td>{result.price.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                    <td>{result.profit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </>
                                }
                            </table>

                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

export default TradePackCalculator;