import React, { useEffect, useState } from "react";

import plaraven from '../../assets/images/playraven.webp';

import { TwitchEmbed } from 'react-twitch-embed';
import './style.css';

function Footer() {
    const [stream, setStream] = useState({});

    useEffect(() => {
        async function fetchData() {
            // check if is localhost
            if (window.location.hostname === 'localhost') {
                return;
            }
            let data = await (await fetch(`${process.env.REACT_APP_TRADEPACK_API}/streamers`)).json();
            let stream = data.stream;
            setStream(stream);
        }
        fetchData();
    }, []);


    return (
        <div className="row mt-2">
            <div className="col-lg-6 col-12 boxes">
                <div className="boxes-title">
                    <div className="twitchIcon text-center"></div>
                    <h4>Featured Stream</h4>

                    {stream.user_name && <TwitchEmbed className="twitch-embed" channel={stream.user_name} autoplay muted darkMode={true} withChat={false} height={363} />}
                </div>
            </div>

            <div className="col-lg-6 col-12 boxes text-center">
                <h4>Play Ravendawn Now!</h4>
                <a href="https://ravendawn.online/?ref=libosi" target="_blank" rel='noreferrer'>
                    <img className="img-fluid playraven" src={plaraven} alt="" />
                </a>
            </div>
        </div>
    )


}

export default Footer;