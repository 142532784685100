import { useEffect, useState } from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { fetchItemMetric } from '../../api/metrics';
import './style.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function BestRoutes() {
  document.title = 'Trade Track - Top 5';
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [tradepacks, setTradepacks] = useState([]);
  const [metricData, setMetricData] = useState({});

  // 5% bonus
  const [bonus5, setBonus5] = useState(false);
  // 10% bonus
  const [bonus10, setBonus10] = useState(false);
  // typeChannel
  const [typeChannel, setTypeChannel] = useState('normal');
  // certificate
  const [certificate, setCertificate] = useState(false);
  // tradepackCount
  const [tradepackCount, setTradepackCount] = useState(1);
  // visualizeOption
  const [visualizeOption, setVisualizeOption] = useState('all');
  // refresh
  const [refresh, setRefresh] = useState(false);

  // onCheckboxChange
  function onCheckboxChange(event) {
    let id = event.target.id;
    let checked = event.target.checked;

    if (id === '5_bonus') {
      setBonus5(checked);
    } else if (id === '10_bonus') {
      setBonus10(checked);
    } else if (id === 'certificate') {
      setCertificate(checked);
    }
  }

  function onVisualizeOptionChange(event) {
    let value = event.target.value;
    setVisualizeOption(value);
  }

  function onTypeChannelChange(event) {
    let value = event.target.value;
    setTypeChannel(value);
  }

  function onRefresh(event) {
    setRefresh(!refresh);
  }

  async function loadItemMetric(id) {
    let world = localStorage.getItem('world');
    let data = await fetchItemMetric(id, world);
    if (data.pricing) {
      data.pricing.pop();
      // data.pricing = data.pricing.slice(-7);
    }
    return data;
  }

  useEffect(() => {
    async function fetchData() {
      let world = localStorage.getItem('world');

      setFinishedLoading(false);
      const response = await fetch(`${process.env.REACT_APP_TRADEPACK_API}/tradepacks?world=${world}`);
      const data = await response.json();

      let tradepacks = [];

      for (const tradepack of data) {
        let price = 0;
        let globalDemand = (tradepack.global_demand || 0).toFixed(0)

        for (const material of tradepack.materials) {
          let amount = material.amount;
          price += material.pricing.medianPrice * amount * tradepackCount;
        }

        tradepacks.push({
          name: tradepack.name,
          url: tradepack.url,
          price: price,
          materials: tradepack.materials,
          globalDemand: globalDemand,
          tradePosts: tradepack.tradeposts
        });
      }

      let seaTradeposts = [
        4, 7, 3, 8, 2, 9
      ];
      let landTradeposts = [
        11, 6, 5, 1, 9, 2, 10, 12
      ];

      let bonus = 0;

      if (bonus5) {
        bonus += 0.05;
      }

      if (bonus10) {
        bonus += 0.10;
      }

      if (typeChannel === 'warmode') {
        bonus += 0.15;
      } else if (typeChannel === 'plunder') {
        bonus += 0.25;
      }

      for (const tradepack of tradepacks) {
        tradepack.best_profit = 0;
        for (const tradePost of tradepack.tradePosts) {
          for (const route of tradePost.routes) {
            let routePrice = 0;
            let demand = route.demand;

            for (let i = 1; i <= tradepackCount; i++) {
              routePrice += route.priceBase * (demand / 100) * (1 + bonus);
              demand -= 1;
            }

            route.price = routePrice;

            if (visualizeOption !== 'all') {
              if (visualizeOption === 'only_sea' && !(seaTradeposts.includes(tradePost.tradepostId) && seaTradeposts.includes(route.tradepostId))) {
                continue;
              } else if (visualizeOption === 'ignore_sea' && !(landTradeposts.includes(tradePost.tradepostId) && landTradeposts.includes(route.tradepostId))) {
                continue;
              }
            }
            let profit = 0;
            if (certificate) {
              profit = route.price
            } else {
              profit = route.price - tradepack.price;
            }
            if (profit > tradepack.best_profit) {
              tradepack.best_profit = profit;
            }
          }
        }

        let bestRoutes = [];

        for (const tradePost of tradepack.tradePosts) {
          for (const route of tradePost.routes) {

            if (visualizeOption !== 'all') {
              if (visualizeOption === 'only_sea' && !(seaTradeposts.includes(tradePost.tradepostId) && seaTradeposts.includes(route.tradepostId))) {
                continue;
              } else if (visualizeOption === 'ignore_sea' && !(landTradeposts.includes(tradePost.tradepostId) && landTradeposts.includes(route.tradepostId))) {
                continue;
              }
            }

            bestRoutes.push({
              routeName: tradePost.name + ' -> ' + route.tradepostName,
              tradepost: tradePost,
              tradepostName: route.tradepostName,
              demand: route.demand,
              price: route.price
            });
          }
        }
        bestRoutes.sort((a, b) => {
          return b.price - a.price;
        });

        bestRoutes = bestRoutes.slice(0, 5);

        tradepack.bestRoutes = bestRoutes;
      }

      tradepacks.sort((a, b) => {
        return b.best_profit - a.best_profit;
      })

      setTradepacks(tradepacks);
      setMetricData({});
      setFinishedLoading(true);
    }

    fetchData();
  }, [bonus5, bonus10, typeChannel, certificate, visualizeOption, tradepackCount, refresh]);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Top 5 tradepack routes</h5>
        <div className="mb-3">
          <label className="me-2" htmlFor="tradepostOrigin">Reputation bonuses: </label>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="checkbox" id="5_bonus" onChange={onCheckboxChange} />
            <label className="form-check-label" htmlFor="5_bonus">
              5%
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="checkbox" id="10_bonus" onChange={onCheckboxChange} />
            <label className="form-check-label" htmlFor="10_bonus">
              10% |
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="typeChannel" value="normal" id="typeChannelNormal" checked={typeChannel === 'normal'} onChange={onTypeChannelChange} />
            <label className="form-check-label" htmlFor="typeChannelNormal">
              Normal
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="typeChannel" value="warmode" id="typeChannelWarmode" checked={typeChannel === 'warmode'} onChange={onTypeChannelChange} />
            <label className="form-check-label" htmlFor="typeChannelWarmode">
              Warmode
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="typeChannel" value="plunder" id="typeChannelPlunder" checked={typeChannel === 'plunder'} onChange={onTypeChannelChange} />
            <label className="form-check-label" htmlFor="typeChannelPlunder">
              Plunder |
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="checkbox" id="certificate" onChange={onCheckboxChange} />
            <label className="form-check-label" htmlFor="certificate">
              Certificate |
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="sea" value="all" id="allSea" checked={visualizeOption === 'all'} onChange={onVisualizeOptionChange} />
            <label className="form-check-label" htmlFor="allSea">
              All routes
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="sea" value="only_sea" id="onlySea" checked={visualizeOption === 'only_sea'} onChange={onVisualizeOptionChange} />
            <label className="form-check-label" htmlFor="onlySea">
              Sea only
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="sea" value="ignore_sea" id="ignoreSea" checked={visualizeOption === 'ignore_sea'} onChange={onVisualizeOptionChange} />
            <label className="form-check-label" htmlFor="ignoreSea">
              Land only
            </label>
          </div>
          <div className="form-check form-check-inline">
            <select className="form-select" id="tradepackCount" onChange={(event) => setTradepackCount(event.target.value)}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>

          <button type="button" className="btn btn-primary" onClick={onRefresh} id="refresh">
            Refresh
          </button>
        </div>

        {
          !finishedLoading && <div className="d-flex justify-content-center">
            <div className="spinner-border m-5" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
        {
          finishedLoading && tradepacks.length > 0 &&

          <div className="mb-3" id="tradepacks">
            <table className="table table-dark table-striped table-bordered table-sm">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Crafting Price</th>
                  <th scope="col">Materials</th>
                  <th scope="col">Global Demand</th>
                  <th scope="col">Top 5 Routes</th>
                </tr>
              </thead>
              <tbody>
                {/* for in tradepacks */
                  tradepacks.map((tradepack, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-middle">
                          <img src={tradepack.url} alt={tradepack.name} /> {tradepack.name}
                        </td>
                        <td className="align-middle text-center">{tradepack.price.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                        <td className="align-middle">
                          <ul>
                            {/* for in materials */
                              tradepack.materials.map((material) => {
                                let price = material.pricing.medianPrice * material.amount * tradepackCount;
                                price = price.toFixed(0);
                                price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                                let lastUpdated = new Date(material.pricing.lastUpdated).toLocaleString();
                                let medianPrice = material.pricing.medianPrice.toFixed(0);
                                let smallestPrice = material.pricing.smallestPrice.toFixed(0);
                                let highestPrice = material.pricing.highestPrice.toFixed(0);

                                const handleMouseEnter = async () => {
                                  // check if data is already loaded
                                  if (metricData[material.clientId]) {
                                    return;
                                  }
                                  let data = await loadItemMetric(material.clientId);
                                  material.metrics = data;
                                  let prices = [];
                                  let labels = [];
                                  let median = 0;
                                  let cont = 1
                                  for (const metric of material.metrics.pricing) {
                                    // metric.price += material.taxToInfuse;
                                    prices.push(metric.price);

                                    median += metric.price;

                                    labels.push(
                                      cont
                                    );
                                    cont++;
                                  }


                                  median = median / material.metrics.pricing.length;

                                  // diff of last price and median
                                  let lastPrice = material.pricing.medianPrice;
                                  let diff = lastPrice - median;
                                  // percentage of diff
                                  let percentage = (lastPrice * 100) / median - 100;

                                  // add last price to prices
                                  prices.push(lastPrice);
                                  labels.push(cont);


                                  const lineData = {
                                    labels,
                                    datasets: [
                                      {
                                        drawActiveElementsOnTop: false,
                                        fill: true,
                                        pointStyle: false,
                                        tension: 0.4,
                                        label: 'Median Price',
                                        data: prices,
                                        borderColor: 'rgb(53, 162, 235)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                      }
                                    ],
                                  };

                                  let metrics = {
                                    lineData: lineData,
                                    diff: diff,
                                    percentage: percentage,
                                    median: median
                                  }
                                  setMetricData(prevData => ({
                                    ...prevData,
                                    [material.clientId]: metrics
                                  }));
                                };

                                const options = {
                                  responsive: true,
                                  animation: false,
                                  layout: {
                                    padding: 0
                                  },
                                  datasets: {
                                    line: {
                                      pointRadius: 0 // disable for all `'line'` datasets
                                    }
                                  },
                                  plugins: {
                                    legend: {
                                      position: 'top',
                                      display: false
                                    },
                                    title: {
                                      display: false,
                                      text: 'Median Price',
                                    },
                                    tooltip: {
                                      enabled: false
                                    }
                                  },
                                  scales: {
                                    y: {
                                      suggestedMin: 0,
                                      display: true
                                    },
                                    x: {
                                      display: false
                                    }
                                  }
                                };

                                const popover = (
                                  <Popover id="popover-basic" style={{ maxWidth: '550px'}}>
                                    <Popover.Header as="h3">
                                    <img src={material.url} alt={material.name} title={material.name} width={25} height={25} />
                                    {material.name}
                                    </Popover.Header>
                                    <Popover.Body>
                                      {metricData[material.clientId] ? (
                                        <>
                                          <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-end">
                                              <h6>Median: <small>{medianPrice}</small></h6>
                                              <h6>Smallest: <small>{smallestPrice}</small></h6>
                                              <h6>Highest: <small>{highestPrice}</small></h6>
                                            </div>
                                            <div className="m-1">
                                              <Line options={options} data={metricData[material.clientId].lineData} />
                                            </div>
                                            <div className="flex-grow-1 d-flex flex-column align-items-center">
                                              <small>30d variation</small>
                                              <span className={metricData[material.clientId].diff > 0 ? 'text-danger h4' : 'text-success h4'}>
                                                {metricData[material.clientId].diff > 0 ? '+' : ''}
                                                {metricData[material.clientId].percentage.toFixed(2)}%
                                              </span>
                                            </div>
                                          </div>
                                          <div className="text-center">
                                            <small>Last 30 days median price: {metricData[material.clientId].median.toFixed(2)}. Last Updated: {lastUpdated}</small>
                                          </div>
                                        </>

                                      ) : (
                                        <div>Loading...</div>
                                      )}
                                    </Popover.Body>
                                  </Popover>
                                );

                                return (
                                  <div key={material.id} onMouseEnter={handleMouseEnter}>
                                    <OverlayTrigger trigger={"hover"} placement="left-end" overlay={popover}>
                                      <div style={{cursor: 'help'}}>
                                        <img src={material.url} alt={material.name} title={material.name} width={25} height={25} />
                                        {material.name} x{material.amount * tradepackCount} ({price})
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                );
                              })
                            }
                          </ul>
                        </td>
                        <td className="align-middle text-center">{tradepack.globalDemand}%</td>
                        <td>
                          <table className="table table-dark table-striped table-sm table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Route</th>
                                <th scope="col">Demand</th>
                                <th scope="col">Sell Price</th>
                                <th scope="col">Profit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                tradepack.bestRoutes.map((route) => {
                                  let profit = route.price - tradepack.price;
                                  profit = profit.toFixed(0);
                                  profit = profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                  let sellPrice = route.price.toFixed(0);
                                  sellPrice = sellPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                                  return (
                                    <tr key={route.routeName}>
                                      <td>{route.routeName}</td>
                                      <td>{route.demand.toFixed(0)}%</td>
                                      <td>{sellPrice}</td>
                                      <td>{profit}</td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  );
}

export default BestRoutes;
