import './App.css';
import { Outlet } from "react-router-dom";


// import header
import Header from './components/header';
import Footer from './components/footer';


function App() {
    // add data-bs-theme="dark" to the body tag to enable dark mode
    document.body.setAttribute('data-bs-theme', 'dark');
    const maintenance = process.env.REACT_APP_MAINTENANCE;

    return (
        <div className="container">
            {maintenance === 'true' && 
                <>
                    <Header />
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Maintenance</h5>
                            <p className="card-text">The website is under maintenance, please come back later.</p>
                        </div>
                    </div>
                    <Footer />
                </>
            }
            {maintenance === 'false' && 
            <><Header /><Outlet /><Footer /></>
            }
        </div>
    );
}

export default App;
