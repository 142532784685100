import { useEffect, useState } from "react";

import { DataTable } from 'primereact/datatable';
import { Column, } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from "primereact/selectbutton";

import "./style.css";

import MetricPopover from "../../components/metric_popover";

function CraftingCalculator() {
    document.title = 'Trade Track - Crafting Calculator';

    const [finishedLoading, setFinishedLoading] = useState(false);
    const [profession, setProfession] = useState({ id: 0, name: 'All' });
    const [craftings, setCraftings] = useState([]);
    const [trackedItems, setTrackedItems] = useState([]);
    const [metricData, setMetricData] = useState({});
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const professions = [
        { id: 0, name: 'All' },
        { id: 4, name: 'Carpentry' },
        { id: 5, name: 'Blacksmithing' },
        { id: 7, name: 'Weaving' },
        { id: 8, name: 'Cooking' },
        { id: 11, name: 'Alchemy' },
    ]

    async function changeProfession(profession) {
        if (profession != null) {
            setProfession(profession);
        }
    }

    const renderHeader = () => {
        return (
            <div className="d-flex justify-content-start">
                <div className="me-2">
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </div>
                <div>
                    <SelectButton optionLabel="name" options={professions} value={profession} onChange={(e) => changeProfession(e.value)} />
                </div>
            </div>
        );
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    useEffect(() => {
        let world = localStorage.getItem('world');
        async function fetchData() {
            setFinishedLoading(false);

            let response = await fetch(`${process.env.REACT_APP_TRADEPACK_API}/crafting?world=${world}`);
            let data = await response.json();

            let trackedItems = await (await fetch(`${process.env.REACT_APP_TRADEPACK_API}/v2/tracked_items`)).json();
            setTrackedItems(trackedItems);
            debugger
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                item.id = i
                let profession = professions.find(profession => profession.id === item.profession);
                item.profession = profession;
            }

            // filter by professions
            if (profession.id !== 0) {
                data = data.filter(item => item.profession.id === parseInt(profession.id));

            }

            setCraftings(data);
            setFinishedLoading(true);
        }
        fetchData();
        // eslint-disable-next-line
    }, [profession])

    const header = renderHeader();

    function capitalizeWords(string) {
        return string.split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ');
    }


    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Crafting Calculator</h5>
                <div>
                    <DataTable value={craftings}
                        tableStyle={{ minWidth: '50rem' }}
                        size="small"
                        showGridlines
                        paginator
                        stripedRows
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        sortField="expPerSilver"
                        sortOrder={-1}
                        filters={filters} globalFilterFields={['name']} header={header}
                        loading={!finishedLoading}
                    >
                        <Column field="name" header="Name" sortable filterField="name"
                            body={(rowData) => {
                                return (
                                    <div>
                                        {rowData.image && <img src={rowData.image} alt={rowData.name} className="rounded" width="32" height="32" />} x{rowData.produce} {capitalizeWords(rowData.name)}
                                    </div>
                                )
                            }}
                        />
                        <Column field="level.single" header="Level" sortable filterField="level.single"
                            body={(rowData) => {
                                return (
                                    <div>
                                        <span className="badge bg-secondary">{rowData.level?.single}</span>
                                        <span className="badge bg-primary">{rowData.level?.mass}</span>
                                    </div>
                                )
                            }}
                        />
                        <Column field="experience" header="Experience" sortable
                            body={(rowData) => {
                                return (
                                    <div>
                                        {rowData.experience.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                    </div>
                                )
                            }}
                        />
                        <Column field="materials" header="Materials"
                            body={(rowData) => {
                                return (
                                    <div>
                                        {rowData.materials.map((material, index) => {
                                            if (trackedItems[material.itemid]) {
                                                let material_price = material.price.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                                let price_in_market = material.marketPrices.medianPrice * material.amount;
                                                price_in_market = price_in_market.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")

                                                return (
                                                    <MetricPopover
                                                        clientid={material.itemid}
                                                        name={capitalizeWords(material.name)}
                                                        image={material.image}
                                                        smallestPrice={material.marketPrices?.smallestPrice}
                                                        highestPrice={material.marketPrices?.highestPrice}
                                                        medianPrice={material.marketPrices?.medianPrice}
                                                        metricData={metricData}
                                                        setMetricData={setMetricData}
                                                    >
                                                        <div key={index}>
                                                            x{material.amount} {material.image && <img src={material.image} alt={material.name} className="rounded" width="24" height="24" />} {capitalizeWords(material.name)} ({material_price}) {price_in_market}
                                                        </div>
                                                    </MetricPopover>
                                                )
                                            } else {
                                                return (
                                                    <div key={index}>
                                                        x{material.amount} {material.image && <img src={material.image} alt={material.name} className="rounded" width="24" height="24" />} {capitalizeWords(material.name)} ({material.price?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")})
                                                    </div>
                                                )
                                            }
                                         
                                        })}
                                    </div>
                                )
                            }}
                        />
                        <Column field="price" header="Price to Craft" sortable
                            body={(rowData) => {
                                return (
                                    <div key={rowData.itemid}>
                                        <div>
                                            {rowData.price.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                        </div>
                                    </div>
                                )
                            }}
                        />
                        <Column field="pricePerItem" header="Price/Item" sortable
                            body={(rowData) => {
                                return (
                                    trackedItems[rowData.itemid] ? (
                                        <MetricPopover
                                            clientid={rowData.itemid}
                                            name={capitalizeWords(rowData.name)}
                                            image={rowData.image}
                                            smallestPrice={rowData.marketPrices?.smallestPrice}
                                            highestPrice={rowData.marketPrices?.highestPrice}
                                            medianPrice={rowData.marketPrices?.medianPrice}
                                            metricData={metricData}
                                            setMetricData={setMetricData}
                                        >
                                            <div className="d-flex">
                                                <div>
                                                    {rowData.pricePerItem.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                </div>
                                                {rowData.marketPrices ? (
                                                    <div className="ms-1">
                                                        {/* if highter red else green */}
                                                        <span className={rowData.marketPrices.medianPrice > rowData.pricePerItem ? 'text-danger' : 'text-success'}>
                                                            {rowData.marketPrices.medianPrice}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </div>
                                        </MetricPopover>
                                    ) : (
                                        <div key={rowData.itemid}>
                                            {/* if marketPrices show median price with color red if maior ou price and green if lower */}
                                            <div>
                                                {rowData.pricePerItem.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                            </div>
                                        </div>
                                    )

                                )
                            }}
                        />
                        <Column field="expPerSilver" header="Exp/Silver" sortable />
                    </DataTable>
                </div>
            </div>
        </div>
    )
}


export default CraftingCalculator